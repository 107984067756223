import React from "react"
import { Layout } from "../components/layout/Layout"

const NotFoundPage = () => (
  <Layout>
    <div>Not Found</div>
  </Layout>
)

export default NotFoundPage
